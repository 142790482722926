import AgreementComponent from "./AgreementComponent";
import AwardComponent from "./AwardComponent";
import BonusPolicyComponent from "./BonusPolicyComponent";
import CustomerVerification from "./CustomerVerification";
import SportsModal from "./sports/SportsModal";
import ContactComponent from "./ContactComponent";
import FAQComponent from "./FaqComponent";
import GamblingComponent from "./GamblingComponent";
import ChangePasswordComponent from "./ChangePasswordComponent";
import GameGuideComponent from "./GmeGuideComponent";
import RulesComponent from "./RulesComponent";
import DepositBonus from "./DepositBonus";
import { useEffect, useRef, useState } from "react";

const GeneralModalWrapper = ({login, goToCashier, type, status, closePopup, openPopup, zIndex = 1060, ...restProps }) => {
  const modalContentRef = useRef(null);
  const [display, setDisplay] = useState("none");
  const [opacity, setOpacity] = useState(1);

  let modal = document.getElementById("genaralModal");
  window.onclick = function(event) {
    if (event.target == modal) {
      handleModalClose()
    }
  }
  const handleModalClose = () => {
    // setTimeout(() => closePopup(), 1000);

    modal.style.opacity = "0"; 
    setTimeout(function() {
      // modal.style.display = "none";
      closePopup();
      setOpacity(0);
    }, 300);

  };

  useEffect(() => {
    // modalContentRef.current.scrollTop = 0;
  }, [type]);

  useEffect(() => {
    // alert(status);
    if (type == "contact") restProps["data-bs-backdrop"] = "static";
    // if (status === "active") document.body.style.overflow = "hidden";
    // return () => (document.body.style.overflow = "scroll");
  }, [status]);

  useEffect(() => {
    if (status === "active") {
      setDisplay("block");
      setTimeout(() => setOpacity(5), 10);

      // setOpacity(1);
    }
  }, [status]);
  return (
    <>
    { status == "active" ? (
    <div className="modal showModal" id="genaralModal" {...restProps} aria-labelledby="exampleModalLabel"
      style={{
        backgroundColor: "#000000a1",
        zIndex,
        // width: type === "sportsModal" ? "60%" : "initial",
      }}
    >
      <div className={`modal-dialog modal-dialog-scrollable showModalcontent ${type === "sportsModal" ? "modal-md" : "modal-lg"}`}>
        <div className="modal-content popupBg">
          <div className="modal-header">
            <button  type="button" className="btn-close"
              onClick={(e) => {
                e.preventDefault();
                // handleModalClose();
                closePopup();
              }}  aria-label="Close" style={{ backgroundColor: "white" }} ></button>
          </div>
          <div className="modal-body" ref={modalContentRef}>
            {type === "contact" ? (
              <ContactComponent />
            ) : type === "rules" ? (
              <RulesComponent />
            ) : type === "DepositBonus" ? (
              <DepositBonus closePopup={closePopup} />
            ) : type === "agreement" ? (
              <AgreementComponent />
            ) : type === "guide" ? (
              <GameGuideComponent />
            ) : type === "faq" ? (
              <FAQComponent />
            ) : type === "bonus" ? (
              <BonusPolicyComponent />
            ) : type === "award" ? (
              <AwardComponent />
            ) : type === "changePassword" ? (
              <ChangePasswordComponent />
            ) : type === "customerVerification" ? (
              <CustomerVerification />
            ) : type === "sportsModal" ? (
              <SportsModal goToCashier={goToCashier} closePopup={closePopup} login={login} openPopup={openPopup}/>
            ) 
            : (
              <GamblingComponent />
            )}
          </div>
          {/* <div className="modal-footer"></div> */}
        </div>
      </div>
    </div>) : null }
    </>
  );
};

export default GeneralModalWrapper;