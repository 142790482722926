import { useState, useEffect, Fragment } from "react";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import axios from "axios";
import { CRYPTOURL, CRYPTO_MERCHANT, DEOSITBONUSURL,} from "../../DataStore/ApiConstant";
import BonusOffer from "../TransactionCommon/BonusOffer";
import { useAtom } from "jotai";
import { bonusStore } from "../../DataStore/atom";
import { getFormData, calculateBonus, calculateTurnover } from "../../utils";
import { useDepositBonus } from "../../services/cashier";
import { useCurrencyRates } from "../../services/cashier";
import { useInrToUsd } from "../../services/config";
import {NumericFormat} from "react-number-format";
import QRupiComponent from "../../Components/Modal/GeneralModal/QRupiComponent";
import { Image } from "react-bootstrap";

import { useParams } from 'react-router-dom';
const currencyRules = [
  { currency: "USD", min: 50, max: 5000 }
];

const getValidatorSchema = () => {
  const { min, max } = currencyRules.find((rule) => rule.currency === "USD");
  return yup.object().shape({
    amount: yup
      .number()
      // .required("Please provide amount.")
      .min(min, `Must be greater than or equal to ${min}`)
      .max(max, `Must be less than or equal to ${max}`)
      .typeError("Amount must be a number"),
  });
};


const Crypto = () => {
  const [BonusStore, setBonusStore] = useAtom(bonusStore);
  const [depositBonus, setDepositBonus] = useState(true);
  const [reBonus, setReBonus] = useState(0);
  const [reTurn, setReTurn] = useState(0);
  const [usd, setUsd] = useState(0);
  const [load, setLoad] = useState(false);

  const [lkrAmount, setLkrAmount] = useState(0);
  const [inrAmount, setInrAmount] = useState(0);

  const [selectedOption, setSelectedOption] = useState(1);


  const[currenyData , setCurrencyData] = useState([]);

  const { type } = useParams();
  const [showSuccessMessage, setShowSuccessMessage] = useState(null);
  const [amount, setAmount] = useState(0);
  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const schema = getValidatorSchema();
  const { handleSubmit, formState: { errors }, register, reset } = useForm({ resolver: yupResolver(schema) });
  
  // const bonus = useWithdrawBonus();
  const currency = useCurrencyRates();
  const bonus = useDepositBonus();
  const InrToUsd = useInrToUsd();
  // console.log(currency?.data?.data[0]?.rate);
  // console.log(currency?.data?.data[36]?.rate);
  
  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      // style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const handleBackClick = () => {
    window.location.href = '/cashier';
  };

  const submitHandler = async () => {
    if(amount === "" || amount <= 0){
      setPaymentErr("Please enter a valid amount")
      const timer = setTimeout(() => {
        setPaymentErr(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
    // setLoad(true)
    // let bonusAmt = 0;
    // depositBonus == null ? (bonusAmt = 0) : (bonusAmt = 1);
    // const formData = new FormData();
    // formData.append('amount', usd);
    // formData.append('url', "/cashier");
    // formData.append('bonus', bonusAmt);
    // axios
    //   .post(CRYPTOURL, formData)
    //   .then((resp) => {
    //     // console.log(resp?.data?.data.token);
    //     let ResState = resp?.data?.state
    //     let token = resp?.data?.data.token
    //     if(ResState == true){
    //       let url = CRYPTO_MERCHANT + token;
    //       window.location.href = url;
    //       // setSuccessMsg(resp.data.message || 'Please Wait');
    //       setLoad(false);
    //     }
    //     setErrmsg('');
    //   })
    //   .catch((err) => {
    //     setPaymentErr(err);
    //     setLoad(false);
    //   })
    setLoad(true)
    let bonusAmt = 0;
    depositBonus == null ? (bonusAmt = 0) : (bonusAmt = 1);
    const formData = {"bonus": bonusAmt, 'fiatAmount': String(amount),'provider': "crypto", "currencyType": String(selectedCurrency) }

    axios
      .post(CRYPTOURL, formData)
      .then((resp) => {
        // if(resp?.data?.success){
        //   const redirectUrl = resp.data.url;
        //   window.location.href = redirectUrl;
        //   setLoad(false);
        // }
        // setErrmsg('');
        if (resp?.data?.success) {
          if(resp?.data?.type == "qr"){
            setQrCodeUrl(resp?.data?.value);
            setIsModalOpen(true)
            setLoad(false);
            return
          }
          else{
            const redirectUrl = resp?.data?.value;
            window.location.href = redirectUrl;
            setLoad(false);
            return
          }
        } else {
          setErrmsg(resp?.data?.error || 'An unknown error has occured')
          setLoad(false);
        }
      })
      .catch((err) => {
        setPaymentErr(err);
        setLoad(false);
      })
  };


              const [selectedCurrency, setSelectedCurrency] = useState(null);

              // Handler to set the icon URL when an image is clicked
              const handleCurrencyClick = (type) => {
                setSelectedCurrency(type);
              };

              const fetchCurrencyData = async () => {

                // Fetch available crypto currencies
              fetch('/api/paymentgws/crypto_currencies')
              .then(response => response.json())
              .then(data => {
                  console.log("data",data)
                  setCurrencyData(data)

              });
              }

              useEffect(() => {
                fetchCurrencyData();
              }, []);
  





  const handleFocus = (event) => {
    event.target.select();
  };
  
  const calculateBonuses = (reAmount) => {

    setAmount(reAmount);
    
    if (bonus.data) {
      let bonusRet = calculateBonus(
        reAmount,
        bonus?.data?.maxbonus || 0,
        bonus?.data?.bonusprecent || 0
      );
      setReBonus(bonusRet);
      let turnRet = calculateTurnover(
        reAmount,
        bonus?.data?.maxbonus || 0,
        bonus?.data?.turnover || 0,
        bonus?.data?.bonusprecent || 0
      );
      setReTurn(turnRet)
      
    }
    // if(!InrToUsd.isLoading && InrToUsd?.data){
      
    // let lkrToUsdAmount = parseFloat(reAmount) * parseFloat(InrToUsd?.data?.[0]?.value || 0.012)
    // let lkrToUsdAmount = (parseFloat( !currency.isLoading && currency?.data?.data?.[0]?.rate) / parseFloat(!currency.isLoading && currency?.data?.data?.[36]?.rate) * parseFloat(reAmount))
    let usdCurrency = !currency.isLoading && currency?.data?.data.find(item => item.cur === "USDT")
    let inrCurrency = !currency.isLoading && currency?.data?.data.find(item => item.cur === "INR")
    
    let calculatedInr = (parseFloat(usdCurrency.rate) / (parseFloat( inrCurrency.rate)) * parseFloat(reAmount))
    let calculatedLkr = (parseFloat(reAmount) * parseFloat(usdCurrency.rate))
    setInrAmount(calculatedInr.toFixed(0))
    setLkrAmount(calculatedLkr.toFixed(0))

      const { min, max } = currencyRules.find((rule) => rule.currency === "USD");
      if (reAmount !== "" && (reAmount < min || reAmount > max)) {
        setPaymentErr(`USDT amount must be between min and max limit: ${min} - ${new Intl.NumberFormat('en-LK', { style: 'currency', currency: 'LKR', maximumFractionDigits: 0 }).format(max)}`);
        setLoad(true)
      } else {
        setPaymentErr(false);
        setLoad(false)
      }
      // lkrToUsdAmount = parseFloat(lkrToUsdAmount.toFixed(3));
      // setUsd(lkrToUsdAmount || 0)
    // }
  };
  const bonusOfferSwitch = async (status) => {
    setSelectedOption((prevOption) => (prevOption === status ? "" : status));

    setBonusStore(status);
    if (status) {
      axios
        .get(DEOSITBONUSURL)
        .then((resp) => {
          setDepositBonus(resp);
        })
        .catch((err) => {
          
        });
    } else {
      setDepositBonus(null);
    }
  };

  useEffect(() => {
    setSelectedCurrency(null);
    reset();
  }, [reset]);

  useEffect(() => {
    if (type == "success") {
      const cryptoTab = document.getElementById('v-pills-crypto-tab');
      if (cryptoTab) cryptoTab.click()
      setShowSuccessMessage("Your transaction was successful!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
    if(type == "cancel"){
      const cryptoTab = document.getElementById('v-pills-crypto-tab');
      if (cryptoTab) cryptoTab.click()
      setShowSuccessMessage("Your transaction failed!");
  
      const timer = setTimeout(() => {
        setShowSuccessMessage(null);
      }, 5000);
  
      return () => clearTimeout(timer);
    }
  }, [type]);
  return (
    <Fragment>
    <div
      className="tab-pane fade "
      id="v-pills-crypto"
      role="tabpane5"
      aria-labelledby="v-pills-crypto-tab"
    >




{selectedCurrency ? (
 <div className="row">
 <form onSubmit={handleSubmit(submitHandler)}>
     <div className="">
           <div className="row d-flex justify-content-center">

     <div className="row d-flex justify-content-center my-5">

     <div className="col-md-4 mb-3">
       <div className="row">
         <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
           Deposit Amount in {selectedCurrency === 'USDT' ? 'USDT' : 'USD'}
         </label>
         <div className="input-group">
           <span
             className="input-group-text"
             style={{
               border: "1px solid #2f2f2f",
               fontWeight: 600,
               backgroundColor: "#c7c7c7"
             }}
           >
             {selectedCurrency === 'USDT' ? 'USDT' : 'USD'}
           </span>
           <NumericFormat
               style={{textAlign: "right", height: "2.5rem" }}
               value={amount}
               {...register("amount")}
               thousandSeparator={true}
               // decimalScale={2}
               fixedDecimalScale={true}
               className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
               onValueChange={(values) => {
                 const { value } = values;
                 calculateBonuses(value);                      
               }}
               onFocus={handleFocus}
               placeholder="0"
               // customInput="input"
             />
           <div className="col-12">
             <div className="form-text text-center">
               Min/Max Limit: {selectedCurrency === 'USDT' ? 'USDT' : 'USD' } 50 / {selectedCurrency === 'USDT' ? 'USDT' : 'USD'} 5,000
             </div>
           </div>
           {/* <div class="form-text">Min/Max Limit: INR 500 / INR 5000</div> */}
         </div>
         {amount != 0 ? (
           <div className="col-md-12 d-flex">
             <div className="preview-box flex-fill">
               In INR: <span id="preview1">{new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(inrAmount)}</span>
             </div>
             <div className="preview-box flex-fill">
               In LKR: <span id="preview2">{new Intl.NumberFormat('en-LK', { currency: 'LKR' }).format(lkrAmount)}</span>
             </div>
           </div>
         ) : ""}
       </div>
     </div>

       
       {/* <div className="col-md-4 mb-3"> */}
         {/* <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
           Enter Amount in INR
         </label> */}
         {/* <div className="input-group"> */}
           {/* <span
             className="input-group-text"
             style={{
               border: "1px solid #2f2f2f",
               fontWeight: 600,
               backgroundColor: "#c7c7c7"
             }}
           >
             INR
           </span> */}
           {/* <input
             type="text"
             className="form-control"
             aria-label="Dollar amount (with dot and two decimal places)"
           /> */}
           {/* <NumericFormat
             style={{ width: "8rem", textAlign: "right", height: "2.5rem" }}
             value={amount}
             {...register("amount")}
             thousandSeparator={true}
             // decimalScale={2}
             fixedDecimalScale={true}
             className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
             onValueChange={(values) => {
               const { value } = values;
               calculateBonuses(value);                      
             }}
             onFocus={handleFocus}
             // customInput="input"
           /> */}
           {/* <div className="col-12">
             <div className="form-text text-center">
               Min/Max Limit: INR 500 / INR 5000
             </div>
           </div> */}
           {/* <div class="form-text">Min/Max Limit: INR 500 / INR 5000</div> */}
         {/* </div>
       </div> */}
       {/* <div className="col-md-4 mb-3">
         <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
           Chargeble USD Amount{" "}
         </label>
         <div className="input-group">
           <input
             style={{textAlign:"right"}}
             value={formatAmount(usd)}
             className="form-control"
             type="text"
             defaultValue="Readonly input here..."
             aria-label="readonly input example"
             readOnly=""
           />
         </div>
       </div> */}
           {errors?.amount?.message && (
             <div className="invalid-feedback">
               {errors.amount.message}
             </div>
                               )}
       <div className="row d-flex justify-content-center">

       <div className="col-md-3 mb-3">
         <div className="row">
           <div className="col-12" style={{ textAlign: "center" }}>
             <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
               Bonus Activation
             </label>
           </div>
           <div className="d-flex justify-content-center">
             <div className="px-3">
               <div className="form-check">
                 <input
                   className="form-check-input"
                   style={{padding:"0"}}
                   type="radio"
                   defaultValue=""
                   id="flexCheckDefault"
                   checked={selectedOption === 1}
                   onChange={() => bonusOfferSwitch(1)}
                 />
                 <label
                   className="form-check-label mt-1 ms-2"
                   htmlFor="flexCheckDefault"
                 >
                   Yes
                 </label>
               </div>
             </div>
             <div className="px-3">
               <div className="form-check">
                 <input
                   className="form-check-input"
                   style={{padding:"0"}}
                   type="radio"
                   defaultValue=""
                   id="flexCheckDefault"
                   checked={selectedOption === 0}
                   onChange={() => bonusOfferSwitch(0)}
                 />
                 <label
                   className="form-check-label mt-1 ms-2"
                   htmlFor="flexCheckChecked"
                 >
                   No
                 </label>
               </div>
             </div>
           </div>
         </div>
       </div>


         {/* <label htmlFor="exampleFormControlInput1" className="cashierNewTitel">
           Bonus
         </label>
         <div className="fform-check form-switch form-switch-lg">
           <input
             className="form-check-input"
             type="checkbox"
             id="flexSwitchCheckChecked"
             defaultChecked=""
             data-val="1"
             checked={BonusStore}
             onChange={(e) => {
               bonusOfferSwitch(e.target.checked);
             }}
           />
         
           <label
             className="form-check-label"
             htmlFor="flexSwitchCheckChecked"
             style={{ marginLeft: 15, marginTop: 10 }}
           >
             Add Bonus Offer
           </label>
         </div> */}
       </div>
       <div className="row d-flex justify-content-center">
           <div className="col-md-6 mb-3">
               {successMsg && (
                 <div
                   style={{ marginTop: "30px" }}
                   className="alert alert-success"
                 >
                   {successMsg}
                 </div>
               )}
               {errmsg && (
                 <div
                   style={{ marginTop: "30px" }}
                   className="alert alert-danger"
                 >
                   {errmsg}
                 </div>
               )}
               {paymentErr && (
                 <div
                   style={{ marginTop: "30px" }}
                   className="alert alert-danger"
                   role="alert"
                 >
                   {paymentErr}
                 </div>
               )}
               {/* <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                 <button
                   disabled={loader}
                   type="submit"
                   className="newcashierButton"
                 >
                   {loader ? (
                     "Processing..."
                   ) : (
                     <TranslatesContent contentKey="submit" />
                   )}
                 </button>
               </div> */}
             </div>
       </div>
     </div>
       <div>
         {showSuccessMessage && (
           <div className={showSuccessMessage.includes("failed") ? "success-message-error" : "success-message"}>
             {showSuccessMessage}
           </div>
         )}
         {/* The rest of your component code */}
       </div>
       <div class="row d-flex justify-content-center">
       <div className="col-md-4 mb-3 d-flex justify-content-between">
        
       <button
                    className="btn btn-primary btn-lg w-50 me-2"
                    type="button" 
                    onClick={handleBackClick}
                    >BACK
      </button>
      <button
                   type="submit"
                   className="btn btn-primary btn-lg w-50"
                   disabled={load}
                 >
                   CONFIRM
      </button>
             {/* <button type="submit"
                   class="btn btn-primary btn-lg w-100">CONFIRM
                   </button> */}
           </div>
</div>










       
       {/* <div className="col-12 col-lg-6 col-md-6 col-sm-12">
           <div className="row ">
             <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 offset-xl-2 offset-lg-2 offset-md-2 offset-sm-0 offset-0">
               <div className="row"> */}
                 {/* <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 mt-4"> */}
                   {/* <label htmlFor="exampleFormControlInput1" className="cashierNewTitel" style={{fontSize: "25px"}}>
                     Deposit to account
                   </label> */}
                   {/* <div className="col-12 col-lg-12 col-md-7 col-sm-12 col-xl-7 mt-4"> */}

                   {/* <div className="mb-3 row"> */}
                     {/* <label htmlFor="staticEmail" className="col-sm-8 col-form-label">
                     Enter amount in INR :
                     </label> */}
                     {/* <div className="col-sm-4"> */}
                         {/* <NumericFormat
                         style={{ width: "8rem", textAlign: "right", height: "2.5rem" }}
                         value={amount}
                         {...register("amount")}
                         thousandSeparator={true}
                         // decimalScale={2}
                         fixedDecimalScale={true}
                         className={`form-control ${_.isEmpty(errors) ? '' : errors?.amount ? 'is-invalid' : 'is-valid'}`}
                         onValueChange={(values) => {
                           const { value } = values;
                           calculateBonuses(value);
                         }}
                         onFocus={handleFocus}
                         // customInput="input"
                       /> */}
                     {/* </div>
                   </div> */}
                   {/* <div className="col-12 col-lg-12 ">
                     Enter amount in INR
                   </div> */}

                   {/* </div> */}
                   {/* {errors?.amount?.message && (
                     <div className="invalid-feedback">
                       {errors.amount.message}
                     </div>
                   )} */}

                 {/* </div> */}
                 {/* <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 ">
                   <div className="col-12 col-lg-12 col-md-7 col-sm-12 col-xl-7">
                     <div className="mb-3 row">
                         <label htmlFor="staticEmail" className="col-sm-8 col-form-label">
                         Equivalent USD amount :
                         </label>
                         <div className="col-sm-4"> */}
                           {/* <input disabled className="form-control" style={{textAlign: "right", width: "8rem",height: "2.5rem",backgroundColor:"black",color:"white"}}
                             value={formatAmount(usd)}
                           /> */}
                         {/* </div> */}
                         {/* <label htmlFor="staticEmail" className="col-sm-4 col-form-label" style={{border: "1px white solid", padding: "7px", borderRadius: "5px", textAlign: "right",}}>
                         {formatAmount(usd)}
                         </label> */}
                     {/* </div> */}
                   {/* </div> */}
                   {/* <div className="col-12 col-lg-8 ">
                     Chargeble USD amount
                     :
                   </div>
                   <div className="col-12 col-lg-4">
                     {formatAmount(usd)}
                   </div> */}
                 {/* </div> */}
                 {/* <div
                   id="emailHelp"
                   className="form-text text-start"
                   style={{ color: "#fff", fontSize: "18px" }}
                 >
                   <TranslatesContent contentKey="min-max-limit" />: LKR
                   500 / LKR 5000
                 </div> */}
                 {/* <div id="emailHelp" className="form-text text-start" style={{ color: '#818181', marginTop: '10px' }}>
                     <TranslatesContent contentKey="min-max-limit" />
                     {" USD 50 / USD 20,000"}
                   </div> */}
                 {/* <div>
                   <div className="row">
                     <div className="col-12"> */}
                         {/* {successMsg && (
                           <div
                             style={{ marginTop: "30px" }}
                             className="alert alert-success"
                           >
                             {successMsg}
                           </div>
                         )} */}
                         {/* {errmsg && (
                           <div
                             style={{ marginTop: "30px" }}
                             className="alert alert-danger"
                           >
                             {errmsg}
                           </div>
                         )} */}
                         {/* {paymentErr && (
                           <div
                             style={{ marginTop: "30px" }}
                             className="alert alert-danger"
                             role="alert"
                           >
                             {paymentErr}
                           </div>
                         )} */}
                         {/* <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                           <button
                             disabled={loader}
                             type="submit"
                             className="newcashierButton"
                           >
                             {loader ? (
                               "Processing..."
                             ) : (
                               <TranslatesContent contentKey="submit" />
                             )}
                           </button>
                         </div> */}
                       {/* </div>
                     </div>
                 </div>
               </div>
               <div> */}
                 {/* {showSuccessMessage && (
                   <div className={showSuccessMessage.includes("failed") ? "success-message-error" : "success-message"}>
                     {showSuccessMessage}
                   </div>
                 )} */}
               {/* </div>

             </div>
           </div>

       </div> */}
       {/* <BonusOffer name={"deposit-bonus"} bonusAmount={!bonus.isLoading && bonus?.data?.bonusprecent}/> */}
       {/* <div className="col-lg-6 offset-xl-2 offset-lg-3 offset-md-3 offset-sm-0 offset-0 mt-2">
         <div className="row">
           <div className="col-12">
             <div className="row my-3  my-xl-4 my-lg-4 my-md-4 my-sm-4">
               <div className="col-12">
                 <label
                   for="exampleFormControlInput1"
                   className="cashierNewTitel"
                 >
                   <TranslatesContent contentKey="bonus" /> Activation
                 </label>
               </div> */}
               {/* <div className="col-12">
                 <input
                   type="checkbox"
                   className="my-checkbox"
                   data-val="1"
                   checked={BonusStore}
                   onChange={(e) => {
                     bonusOfferSwitch(e.target.checked);
                   }}
                 />
                 <TranslatesContent contentKey="activate" />
               </div> */}
             {/* </div> */}
             {/* {BonusStore ? (
               <div>
                 <div className="row">
                   <div className="col-12 col-lg-5 bonusResultLeft">
                     <TranslatesContent contentKey="bonus-amount" />:
                   </div>
                   <div className="col-12 col-lg-7 bonusResultRight">
                     {reBonus}
                   </div>
                 </div>
                 <div className="row">
                   <div className="col-12 col-lg-5 bonusResultLeft">
                     <TranslatesContent contentKey="withdrawal-turnover" />
                     :
                   </div>
                   <div className="col-12 col-lg-7 bonusResultRight">
                     {reTurn}
                   </div>
                 </div>
               </div>
             ) : null} */}
             {/* {paymentErr && (
               // <div
               //   style={{ marginTop: "30px" }}
               //   className="alert alert-danger"
               // >
               //   {paymentErr}
               // </div>
               <div className="alert alert-danger" role="alert">
                 {paymentErr}
               </div>
             )} */}
             {/* <div className="row mt-4">
               <div className="col-6 col-lg-4 col-md-4 col-sm-4">
                 <button
                   type="submit"
                   className="newcashierButton"
                   disabled={load}
                 >
                   {(
                     <TranslatesContent contentKey="submit" />
                   )}
                 </button>
               </div>
             </div> */}
           </div>
         </div>
 </form>
       </div>
) : (
  <div className="row d-flex justify-content-center visible" id="imageGallery">
    {currenyData.map((item, index) => (
      <div className="col-lg-2 col-md-2 col-sm-2 col-6 mx-0 p-2" key={index}>
         <img
                src={item.icon_url}
                className="clickableImage img-fluid"
                alt={`Currency ${index}`}
                onClick={() => handleCurrencyClick(item.type)} // On click, set the icon URL
              />
      </div>
    ))}
  </div>
)}
            </div>
          
    <div>
      <QRupiComponent
        isOpen={isModalOpen}
        onClose={closeModal}
        qrCodeValue={qrCodeUrl}
      />
    </div>
    </Fragment>
    
  );
};

export default Crypto;
