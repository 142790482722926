import { connect } from "react-redux";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import Carousel from "react-bootstrap/Carousel";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const MainBanner = ({ FirstPageImages, bannerImages, login, openPopup }) => {
  const imagesToShow = login ? FirstPageImages : bannerImages;
  // const imagesToShow = [
  //   "WEB BANNER test 1 1920 X 550.jpg",
  //   "WEB BANNER test 2 1920 X 450.jpg",
  //   "WEB BANNER test 3 1920 X 350.jpg",
  //   "Ballysbet-New-Siite_1.jpg"
  // ]

  return (
    <Carousel indicators={false}>
      {imagesToShow &&
        imagesToShow.map((bannerImg, key) => {
          let imgSrc = `${IMAGE_BUCKET_URL}${bannerImg}`;

          return (
            <Carousel.Item key={key} className="p-0">
              <a>
                <img src={imgSrc} className="d-block w-100" alt="..." />
                {!login && (
                  <div className="position-absolute top-50 start-50 translate-middle sliderButtons text-center">
                    <a
                      className="align-middle hoverButton"
                      onClick={(e) => {
                        e.preventDefault();
                        openPopup("login");
                      }}
                    >
                      <TranslatesContent contentKey="play-now" />
                    </a>
                  </div>
                )}
              </a>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};

const mapStateToProps = (state) => {
  return {
    bannerImages: state.siteImages.images.mainslider,
    FirstPageImages: state.siteImages.images.home_banner,
  };
};

export default connect(mapStateToProps)(MainBanner);
