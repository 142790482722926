import axios from "axios";
import { useEffect, useState } from "react";
import {
  BASE_URL,
  FUND_TRANSFER_TYPE_URL,
  SPORT_LOGIN_URL,
} from "../../DataStore/ApiConstant";
import { connect } from "react-redux";
import { TOAST_CONFIG } from "../../constants";
import WhatsappButton from "./whatsappButton";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { getFormData, validateNumberInput } from "../../utils";
import { fundTransferTypes } from "../../constants/";
import { toast } from "react-toastify";
import TranslatesContent from "../../DataStore/Languages/translatesContent";
import { type } from "@testing-library/user-event/dist/type";
import { fundTransfer } from "../../DataStore";
// import { UserData } from "../../DataStore";
import { UserData } from "../../DataStore";
import { useCurrencyRates } from "../../services/cashier";

let validator = {
  amount: yup
    .number()
    .required("Please provide amount.")
    .min(1, "Must be greater than 0")
    .typeError("Amount must be a number"),
};
// console.log(fundTransferTypes);
const FundTransfer = ({
  fundsTransfer,
  getUserData,
  getFUndTransfers,
  goToCashier,
  userData
}) => {
  useEffect(() => {
    getUserData();
    getFUndTransfers();
  }, []);
  const schema = yup.object().shape(validator);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [transferTypes, setTransType] = useState("");
  const [tranFrom, setTransFrom] = useState("0");
  const [tranTo, setTransTo] = useState(1);
  const [transAmt, setTransAmt] = useState(0);
  const [reqType, setReqType] = useState("sp-dep");
  const [loader, setLoader] = useState(false);
  const [selectError, setSelectError] = useState(false);
  const [load, setLoad] = useState(false);
  const [spLoad, setSpLoad] = useState(false);
  const [dpLoad, setDpLoad] = useState(false);
  const [paymentErr, setPaymentErr] = useState(false);

  const [errmsg, setErrmsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [lkrAmount, setLkrAmount] = useState(0);
  const [inrAmount, setInrAmount] = useState(0);
  const [amount, setAmount] = useState("");

  const currency = useCurrencyRates();
  const sportsLogin = () => {
    setSpLoad(true);
    axios
      .get(SPORT_LOGIN_URL)
      .then((resp) => {
        window.open(resp.data.data.url, "_blank", "noreferrer");
        setSpLoad(false);
      })
      .catch((err) => {
        // toast.error(err?.data?.resp || "err", TOAST_CONFIG);
        setErrmsg(err?.data?.resp);
        setSpLoad(false);
      });
  };
  const setTransFromValue = (e) => {
    setTransFrom(e)
    checkAplicable(e,tranTo)
    // log
  }
  const setTransToValue = (e)=>{
    
    setTransTo(e)
    setAmount("")
    setPaymentErr("")
    // checkAplicable(e)

  }
  const checkAplicable = (tranF,tranT) => {
    if(tranF== 1){
     setErrmsg("Bonus not applicable")
    }else{
      setErrmsg("")
    }
  }
  const openDeposit = async (type) => {
    setDpLoad(true);
    try {
      await goToCashier(type);
    } catch (error) {
      console.error(error);
    }
    setDpLoad(false);
  };
  let submitHandler = async (fundTranferObj) => {
    fundTranferObj = fundTransferTypes[tranTo] != ""  ? 
        { ...fundTranferObj,  type: fundTransferTypes[tranTo][1]["type"], } : fundTranferObj;
    axios.post( BASE_URL + fundTransferTypes[tranTo][1]["url"], getFormData(fundTranferObj) ).then(({data}) => {
      if(tranTo==2){ 
        setErrmsg("Bonus not aplicable")
      }
        if (data.resp == 'invalid amount') {
          setErrmsg(data.resp);
        } 
        else {
          if (data.resp == "pok") {
          
              // if (data.data == 'Success') {
                setSuccessMsg(data.data);

                setTimeout(() => {
                  getUserData();
                  getFUndTransfers(); 
                  setAmount("")
                  setTransTo(1)
                }, 3000);
                setLoad(false);
                reset(); 
            // } else {
            //   setErrmsg(data.data);
            // }
          } else {
            setErrmsg(data.resp);
          }
        }
      }).catch((err) => {
        setErrmsg(err?.data?.resp);
      }).finally(() => {
        setTimeout(() => {
          setErrmsg("");
          setSuccessMsg("")
        }, 3000);
        
      });

    
    setLoad(true);
    if (paymentErr) {
      setLoad(false);
      return;
    }

  };

  const handleFocus = (event) => {
    event.target.select();
  };
  // useEffect(() => {
  //   checkAplicable(tranTo)
  // }, []);

  useEffect(() => {
    axios
      .get(FUND_TRANSFER_TYPE_URL)
      .then((resp) => {
        setTransType(resp.data);
        console.log(resp.data);
        
      })
      .catch((err) => {});
  }, []);

  const ValidateDepositAmount = (reAmount) => {
    setAmount(reAmount);
    // let usdCurrency = !currency.isLoading && currency?.data?.data.find(item => item.cur === "USDT")
    let inrCurrency = !currency.isLoading && currency?.data?.data.find(item => item.cur === "I R")
    
    // let calculatedInr = (parseFloat(usdCurrency.rate) / (parseFloat( inrCurrency.rate)) * parseFloat(reAmount))
    let calculatedLkr = (parseFloat(reAmount) * parseFloat(inrCurrency.rate))
    // setInrAmount(calculatedInr.toFixed(0))
    setLkrAmount(calculatedLkr.toFixed(0))
    setSuccessMsg("");
    if (reAmount != "" && (reAmount < 500 || reAmount > 500000)) {
      setPaymentErr("Please enter amount between min and max limit");
    } else {
      // checkAplicable()
      setPaymentErr(false);
    }
  };

  return (
    <>
    <div class="position-relative">
        <div class="position-absolute top-0 end-0">
            <button class="play-btn" onClick={sportsLogin}>Play</button>
        </div>
    </div>
    <div class="row p-4">
      <div class="col-12 col-lg-10 col-md-8 col-sm-8 col-xl-10 offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-0">
      <form className="row" onSubmit={handleSubmit(submitHandler)}>
      <div className="row d-flex justify-content-center">
          <div className="col-md-8 mb-3">
            <div className="row">
              <div className="p-2 text-center">
                <h4 style={{ fontSize: "1.3rem", fontWeight: 400 }}>Wallet Transfer</h4>
              </div>
              <div className="d-flex justify-content-center">
                <div className="px-3">
                  <div className="form-check">
                    <input
                      onChange={(e) => {setTransToValue(e.target.value)}}
                      className="form-check-input"
                      defaultChecked
                      type="radio"
                      value={1}
                      id="flexCheckDefault"
                      name="flexRadioDefault"
                      style={{ padding: 0 }}
                    />
                    <label
                      className="form-check-label mt-1 ms-2"
                      htmlFor="flexCheckDefault"
                    >
                      Deposit to Sport Wallet
                    </label>
                  </div>
                </div>
                <div className="px-3">
                  <div className="form-check">
                    <input
                      onChange={(e) => {setTransToValue(e.target.value)}}
                      className="form-check-input"
                      type="radio"
                      value={2}
                      name="flexRadioDefault"
                      id="flexCheckDefault"
                      style={{ padding: 0 }}
                    />
                    <label
                      className="form-check-label mt-1 ms-2"
                      htmlFor="flexCheckChecked"
                    >
                      Withdrawal from Sport Wallet
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-md-8 mb-3">
              <div className="row">
              {tranTo == 1 ? (<div class="p-2 text-center">
                    <h4 style={{fontSize: "1rem",fontWeight: "700"}}>Maximum Transferable
                        Amount in INR {fundsTransfer &&
                        fundsTransfer.data &&
                        fundsTransfer.data.data.sports && new Intl.NumberFormat().format(
                          userData?.data?.data1?.amount - fundsTransfer.data.data.bonus_given.balance
                        )}
                      </h4>
                </div>) : ("")}
                
                <div class="p-2 text-center mt-1">
                    <h4 style={{fontSize: "1.2rem",fontWeight: "400"}}>{tranTo == 1 ? ("Deposit") : ("Withdraw")} Amount in INR
                    </h4>
                </div>
                <div class="d-flex justify-content-center">
                  <div className="px-3">
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text"
                        style={{
                          border: "1px solid #2f2f2f",
                          fontWeight: 600,
                          backgroundColor: "#c7c7c7"
                        }}
                      >
                        INR
                      </span>
                      <input
                          onInput={(e) => validateNumberInput(e.target)}
                          type="number"
                          value={amount}
                          className={`form-control text-end${
                            _.isEmpty(errors)
                              ? ""
                              : errors?.amount
                              ? "is-invalid"
                              : "is-valid"
                          }`}
                          onFocus={handleFocus}
                          {...register("amount")}
                          onChange={(e) => {
                            ValidateDepositAmount(e.target.value);
                          }}
                          placeholder=""
                        />
                        
                        {amount != 0 ? (<span class="input-group-text">in LKR: {new Intl.NumberFormat('en-LK', { currency: 'LKR' }).format(lkrAmount)}</span>) : ""} 
                      
                      {/* <div className="col-12">
                        <div className="form-text text-center">
                          Min/Max Limit: INR 500 / INR 5000
                        </div>
                      </div> */}
                      {/* <div class="form-text">Min/Max Limit: INR 500 / INR 5000</div> */}
                    </div>
                    
                  </div>
                </div>
                  <div className="col-12">
                      <div className="form-text text-center">{tranTo == 1 ? ("Min/Max Limit: INR 500 / INR 500,000") : ("")}
                      </div>
                  </div>
            </div>
          </div>



          <div className="row d-flex justify-content-center">
            <div className="col-md-6 mb-3 form-text text-center">
            {errors?.amount?.message ? (
                      <div className="invalid-feedback">
                        {errors?.amount?.message}
                      </div>
                    ) : null}
                    {successMsg !='' ? (
                    <div
                      className="alert alert-success"
                    >
                      {successMsg}
                    </div>
                  ):null}

                  {errmsg !='' ? (
                    <div
                      className="alert alert-danger"
                    >
                      {errmsg}
                    </div>
                  ):null}
                  {paymentErr && (
                    <div
                      class="alert alert-danger"
                      role="alert"
                    >
                      {paymentErr}
                    </div>
                  )}
                  {selectError && (
                    <div
                      className="alert alert-danger"
                      role="alert"
                    >
                      Please select an option before proceeding.
                    </div>
                  )}
            </div>
          </div>
          
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-md-3 mb-3">
            <button className="btn btn-primary btn-lg w-100" type="submit" disabled={load}>
              {tranTo == 2 ? (load ? "Processing" : "WITHDRAW") : (load ? "Processing" : "DEPOSIT")} 
              
            </button>
            {/* <button type="button" class="btn btn-primary btn-lg w-100">PLAY NOW</button> */}
          </div>
        </div>
      </form>
      </div>
    </div>
    </>
    
  );
};

const mapStateToProps = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    userData: state.userData,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getUserData: () => dispatch(UserData()),
  };
};
// const mergeProps = (stateProps, dispatchProps, ownProps) => {
//     return Object.assign({}, ownProps, stateProps, dispatchProps)
//   }

export default connect(mapStateToProps, mapDispatchToProps)(FundTransfer);
