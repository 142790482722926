// import TranslatesContent from "../../../DataStore/Languages/translatesContent";
import { IMAGE_BUCKET_URL, SPORT_LOGIN_URL } from "../../../../DataStore/ApiConstant";
import axios from "axios";
import { useEffect, useState } from "react";

const SportsModal = ({goToCashier, closePopup, login ,openPopup}) => {
const [spLoad, setSpLoad] = useState(false);
const sportsLogin = () => {
    setSpLoad(true);
    axios
        .get(SPORT_LOGIN_URL)
        .then((resp) => {
        window.open(resp.data.data.url, "_blank", "noreferrer");
        setSpLoad(false);
        closePopup();
        })
        .catch((err) => {
        setSpLoad(false);
        });
    };
    return ( 
    <>
    <div className="row d-flex justify-content-center mb-4">
        <img src={IMAGE_BUCKET_URL + "Popup-banner_local.png"} className="img-fluid" alt="" />
        </div>
        <div className="row d-flex justify-content-center ">
            <div className="col-md-6 mb-3">
            {login ? (
            <button
            type="button"
            className="btn btn-primary btn-lg w-100"
            style={{ fontSize: 16 }}
            onClick={sportsLogin}
            disabled={spLoad}
            >
                {spLoad ? (
                    "Redirecting..."
                ) : (
                    "PROCEED TO PLAY"
                )}
            
            </button>) : (
                <button
                type="button"
                className="btn btn-primary btn-lg w-100"
                style={{ fontSize: 16 }}
                onClick={(e) => {
                e.preventDefault();
                closePopup();
                openPopup("login");
                }}
            >
                PROCEED TO PLAY
            </button>
            )}
            </div>
            <div className="col-md-6 mb-3">
            {login ? (<button
            
            type="button"
            className="btn btn-primary btn-lg w-100"
            style={{ fontSize: 16 }}
            onClick={(e) => {
                e.preventDefault();
                goToCashier("fund-transfer");
                closePopup();
            }}
            >
            DEPOSIT TO WALLET
            </button>) : (
                <button
                type="button"
                className="btn btn-primary btn-lg w-100"
                style={{ fontSize: 16 }}
                onClick={(e) => {
                e.preventDefault();
                closePopup();
                openPopup("login");
                }}
            >
                DEPOSIT TO WALLET
            </button>
            )}
            
            </div>
        </div>
    </>
    );
}
 
export default SportsModal;