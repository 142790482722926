import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LOGOUTURL } from "../../DataStore/ApiConstant";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { IMAGE_BUCKET_URL } from "../../DataStore/ApiConstant";
import ChangePasswordComponent from "../Modal/GeneralModal/ChangePasswordComponent";

import {
  fundTransfer,
  getBankAccount,
  getWithdrowData,
  getWithdrowMethods,
  getTransHistory,
  userDataError,
} from "../../DataStore";
import TranslatesContent from "../../DataStore/Languages/translatesContent";

const UserInfo = ({
  userDropdown,
  fundsTransfer,
  getFUndTransfers,
  bankAccount,
  getBankAccount,
  withdrowData,
  getWithdrowData,
  witdrowMethods,
  getWithdrowMethods,
  openPopup,
  userSession,
  userDta,
  transferHistory,
  getTransferHistory,
  openGeneralModal,
  goToCashier,
  setSelectedGameType,
  cashier = "false",
}) => {
  useEffect(() => {
    getFUndTransfers();
    getBankAccount();
    getWithdrowData();
    getWithdrowMethods();
    getTransferHistory();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const history = useHistory();

  const logoutUser = (e) => {
    e.preventDefault();
    axios
      .get(LOGOUTURL)
      .then((resp) => {
        // sessionStorage.removeItem("login");
        localStorage.removeItem('login')
        history.push("/");
      })
      .catch((err) => {});
  };
  const featuredGameClicked = (type) => {
    cashier === "true" ? history.push("/lobby") : setSelectedGameType(type);
  };
  const today = new Date();
  const curHr = today.getHours();
  let greeting = <TranslatesContent contentKey="good-morning" />;

  if (curHr < 12) {
    greeting = <TranslatesContent contentKey="good-morning" />;
  } else if (curHr < 18) {
    greeting = <TranslatesContent contentKey="good-afternoon" />;
  } else {
    greeting = <TranslatesContent contentKey="good-evening" />;
  }

  return (
    <div className="">
      <div
        class="offcanvas offcanvas-end bg-dark"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">&nbsp;</h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            style={{ backgroundColor: "white" }}
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body p-4">
          <div class="row">
            <div class="col-12 dropTitel">PROFILE</div>

            <div class="col-6 dropLeftText">{greeting}</div>
            <div class="col-6 dropRightText">
              {userSession && userSession.username}
            </div>

            <div class="col-6 dropLeftText">
              <TranslatesContent contentKey="user-id" /> :{" "}
            </div>
            <div class="col-6 dropRightText">
              {userSession && userSession.mid}
            </div>

            <div class="col-6 dropLeftText">
              <TranslatesContent contentKey="your-balance" /> :
            </div>
            <div class="col-6 dropRightText">
              {userDta &&
                userDta.amount &&
                new Intl.NumberFormat().format(userDta.amount)}
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6 dropLeftText">
              <TranslatesContent contentKey="cash-balance" /> :
            </div>
            <div class="col-6 dropRightText">
              {userDta &&
                userDta.amount &&
                new Intl.NumberFormat().format(userDta.amount)}
            </div>

            <div class="col-6 dropLeftText">
              Last Bonus :{" "}
            </div>
            <div class="col-6 dropRightText">
              {fundsTransfer.data.data &&
                fundsTransfer.data.data.bonus_given &&
                fundsTransfer.data.data.bonus_given.balance &&
                new Intl.NumberFormat().format(
                  fundsTransfer.data.data.bonus_given.balance
                )}
            </div>
          </div>

          <div class="row my-3">
            {
              userDta && userDta.user_type !== "demo" ? 
              <>
                <div class="col-6">
                  <a
                    href=""
                    data-bs-dismiss="offcanvas"
                    onClick={(e) => {
                      e.preventDefault();
                      goToCashier("deposit");
                    }}
                  >
                    <div class="joinTopbutton" style={{ minWidth: "100px" }}>
                      <TranslatesContent contentKey="deposit" />
                    </div>
                  </a>
                </div>
                <div class="col-6">
                <a
                    href=""
                    data-bs-dismiss="offcanvas"
                    onClick={(e) => {
                      e.preventDefault();
                      goToCashier("withdrow");
                    }}
                  >
                  {/* <div
                    class="joinTopbutton"
                    data-bs-dismiss="offcanvas"
                    onClick={(e) => {
                      e.preventDefault();
                      goToCashier("withdrow");
                    }}
                  >
                    <TranslatesContent contentKey="withdraw-cashier" />
                  </div> */}
                    <div class="joinTopbutton" style={{ minWidth: "100px" }}>
                      <TranslatesContent contentKey="withdraw-cashier" />
                    </div>
                    </a>
                </div>
              </> : ""
            }
            <hr />
            <div class="row">
              <div class="col-12 dropTitel">SECTIONS</div>
              <div class="col-12 dropNavLink1 catTitel">
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    featuredGameClicked("liveCasino");
                  }}
                >
                  {" "}
                  <img
                    src={IMAGE_BUCKET_URL + "nav-icon-1.png"}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  <TranslatesContent contentKey="live-casino" />
                </a>
              </div>
              <div class="col-12 dropNavLink2 catTitel">
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    featuredGameClicked("events");
                  }}
                >
                  {" "}
                  <img
                    src={IMAGE_BUCKET_URL + "nav-icon-2.png"}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  <TranslatesContent contentKey="slots" />
                </a>
              </div>
              <div class="col-12 dropNavLink3 catTitel disabled-div">
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    // featuredGameClicked("loyalty");
                    alert("This is not reachable");
                  }}
                >
                  {" "}
                  <img
                    src={IMAGE_BUCKET_URL + "nav-icon-3.png"}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  {/* <TranslatesContent contentKey="sports" /> */}
                  LOYALTY
                </a>
              </div>
              <div class="col-12 dropNavLink4 catTitel">
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    featuredGameClicked("promotion");
                  }}
                >
                  {" "}
                  <img
                    src={IMAGE_BUCKET_URL + "nav-icon-4.png"}
                    style={{ marginRight: "10px" }}
                    alt=""
                  />
                  <TranslatesContent contentKey="promotions" />
                </a>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 dropTitel">LINK</div>
              {/* <div class="col-12 dropLeftText"> <a href=""> Reward</a></div> */}
              <div class="col-12 dropLeftText">
                {" "}
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    goToCashier("transfer");
                  }}
                >
                  {" "}
                  <TranslatesContent contentKey="transfer-history-profile" />
                </a>
              </div>
              {/* <div class="col-12 dropLeftText">
                {" "}
                <a
                  href=""
                  data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    goToCashier("fund-transfer");
                  }}
                >
                  {" "}
                  <TranslatesContent contentKey="fund-transfer-profile" />
                </a>
              </div> */}
              <div class="col-12 dropLeftText">
                {" "}
                <a
                  href=""
                  // data-bs-dismiss="offcanvas"
                  onClick={(e) => {
                    e.preventDefault();
                    openModal();
                    // openGeneralModal("changePassword");
                  }}
                >
                  {" "}
                  Change password
                </a>
              </div>
              <ChangePasswordComponent isOpen={isModalOpen} onClose={closeModal}/>
            </div>
            <hr />
            <div class="row">
              <div class="col-12 dropTitel">OTHER</div>
              <div class="col-6 text-center">
                <button
                  type="button"
                  data-bs-dismiss="offcanvas"
                  class="btn dropFooterButton"
                  onClick={(e) => {
                    e.preventDefault();
                    openGeneralModal("contact");
                  }}
                >
                  CONTACT
                </button>
              </div>
              <div class="col-6 text-center">
                <button
                  type="button"
                  onClick={(e) => logoutUser(e)}
                  class="btn dropFooterButton2"
                >
                  LOG OUT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const maStateTorops = (state) => {
  return {
    fundsTransfer: state.FundTransfers,
    bankAccount: state.BankAccount,
    withdrowData: state.WidthdrowData,
    witdrowMethods: state.WithdrowMethods,
    transferHistory: state.TransferHistory,
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFUndTransfers: () => dispatch(fundTransfer()),
    getBankAccount: () => dispatch(getBankAccount()),
    getWithdrowData: () => dispatch(getWithdrowData()),
    getWithdrowMethods: () => dispatch(getWithdrowMethods()),
    getTransferHistory: () => dispatch(getTransHistory()),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, dispatchProps);
};

export default connect(maStateTorops, mapDispatchToProps, mergeProps)(UserInfo);
